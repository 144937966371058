import { alpha } from '@mui/material'

import { getDayMonth, getFullDate, getLeftTimeFromMinutes } from '../../utils/date-utils'
import { PLACE_DICT_NO_COME_VOTE, PLACE_DICT_WITH_COME_VOTE } from './PrizeCard/constants'

export const CONTEST_LIFE_STATES = {
  SETUP: 'setup',
  ANNOUNCEMENT: 'announcement',
  SUBMISSION: 'submission',
  QUALIFICATION: 'qualification',
  EVALUATION: 'evaluation',
  RUNOFF: 'run-off',
  FINAL: 'final',
  TIEBREAKER: 'tiebreaker',
  FINISHED: 'finished',
  PUBLISHED: 'published',
}

export const CONTEST_MOBILE_STATES = {
  MAIN: '',
  SUBMIT_MAP: 'submit-map',
  EDIT_SUBMIT: 'edit-submit',
  COMMUNITY_FAVORITES: 'community-favorites',
}

export const CONTEST_COMMUNITY_VOTE_LIFE_STATE = 'community_vote'

export const CONTEST_FINISHED_LIFE_STATES = {
  FINISHED_BEGINNING: 'finished-beginning',
  FINISHED_VOTED: 'finished-voted',
  FINISHED_REVOTING: 'finished-revoting',
  FINISHED_VOTED_PUBLISHING: 'finished-voted-published',
}

const { SUBMISSION, QUALIFICATION, EVALUATION, RUNOFF, FINAL, TIEBREAKER, FINISHED, PUBLISHED } = CONTEST_LIFE_STATES

const { FINISHED_BEGINNING, FINISHED_VOTED, FINISHED_REVOTING, FINISHED_VOTED_PUBLISHING } =
  CONTEST_FINISHED_LIFE_STATES

export const getIsSubmissionRound = (contestState) => contestState === SUBMISSION
export const getIsQualificationRound = (contestState) => contestState === QUALIFICATION
export const getIsEvaluationRound = (contestState) => contestState === EVALUATION
export const getIsRunoffRound = (contestState) => contestState === RUNOFF
export const getIsFinalRound = (contestState) => contestState === FINAL
export const getIsTiebreakersRound = (contestState) => contestState === TIEBREAKER
export const getIsCommunityVoteRound = (contestState) => contestState === CONTEST_COMMUNITY_VOTE_LIFE_STATE
export const getIsFinishedRound = (contestState) => contestState === FINISHED
export const getIsFinishedStartVoteRound = (contestState) => contestState === FINISHED_BEGINNING
export const getIsFinishedVotedRound = (contestState) => contestState === FINISHED_VOTED
export const getIsFinishedReVoteRound = (contestState) => contestState === FINISHED_REVOTING
export const getIsFinishedVotedPublishRound = (contestState) => contestState === FINISHED_VOTED_PUBLISHING
export const getIsPublishedRound = (contestState) => contestState === PUBLISHED
export const getIsVoteRound = (contestState) =>
  [EVALUATION, RUNOFF, FINAL, TIEBREAKER, CONTEST_COMMUNITY_VOTE_LIFE_STATE].includes(contestState)
export const getIsEvaluationOrRunoffRound = (contestState) => [EVALUATION, RUNOFF].includes(contestState)
export const getIsRunoffOrFinalRound = (contestState) => [RUNOFF, FINAL].includes(contestState)
export const getIsFinalistsRound = (contestState) => [FINAL, TIEBREAKER].includes(contestState)
export const getIsRoundValidForCommunityVote = (contestState) => [FINAL, TIEBREAKER, FINISHED].includes(contestState)

export const getTimeLeftFromMinutes = (timeLeft) => {
  const time = getLeftTimeFromMinutes(timeLeft)
  return timeLeft < 60 ? `less than ${time}` : `${time} left`
}

export const getMaxMapToVote = (contestState) =>
  (getIsCommunityVoteRound(contestState) && 3) ||
  (getIsRunoffOrFinalRound(contestState) && 10) ||
  (getIsTiebreakersRound(contestState) && 1) ||
  Infinity
export const getIsMaxMapVoted = (contestState, acceptedListLength) =>
  getMaxMapToVote(contestState) === acceptedListLength

export const getContestStepperState = (contestState) => {
  if (getIsQualificationRound(contestState)) return 1
  if (getIsEvaluationOrRunoffRound(contestState)) return 2
  if (getIsRoundValidForCommunityVote(contestState)) return 3
  if (getIsPublishedRound(contestState)) return 4
  return 0
}

export const getContestWithRoundsStepperState = (contestState, limits, currentLimit) => {
  if (getIsQualificationRound(contestState)) return 1
  if (getIsEvaluationOrRunoffRound(contestState)) return 2 + limits.indexOf(currentLimit)
  if (getIsRoundValidForCommunityVote(contestState)) return 2 + limits.length
  if (getIsPublishedRound(contestState)) return 3 + limits.length
  return 0
}

export const getContestMobileHeaderName = (contest) => {
  if (!contest.state) return ''
  const contestStep = getContestStepperState(contest?.state)
  if (contestStep === 0) return contestStepsForMobile[contestStep].name
  if (contestStep === 4) return getFullDate(contest.time_final_end)
  return `${contestStep - 1}: ${contestStepsForMobile[contestStep].name}`
}

export const getRoundMapListQueryState = (contestState, isContestant) => {
  if (getIsSubmissionRound(contestState)) return SUBMISSION
  if (isContestant) {
    if (getIsQualificationRound(contestState)) return SUBMISSION
    if (getIsEvaluationRound(contestState)) return EVALUATION
    if (getIsRunoffRound(contestState)) return RUNOFF
    if (getIsFinalistsRound(contestState) || getIsFinishedRound(contestState)) return FINAL
  }
  return null
}

export const getRoundMapListTitle = (contestState) => {
  if (getIsRoundValidForCommunityVote(contestState)) return 'Winners!'
  if (getIsEvaluationOrRunoffRound(contestState)) return 'Qualified contestants'

  return 'Submissions'
}

export const filterMerchByPlace = (merchandise, place, isWithComeVote) =>
  (merchandise || []).filter(({ place_from, place_to, name }) => {
    const range = isWithComeVote ? PLACE_DICT_WITH_COME_VOTE[place] : PLACE_DICT_NO_COME_VOTE[place]

    if (place_from && !place_to) place_to = place_from
    else if (place_to && !place_from) place_from = place_to

    return (
      ((place_from <= range[range.length - 1] && place_to >= range[0]) ||
        (place_to <= range[range.length - 1] && place_from >= range[0])) &&
      name
    )
  })

export const getContestSceneStyles = (theme) => ({
  background: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    cursor: 'default',
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1),
    top: 0,
    left: 0,
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  container: {
    display: 'flex',
    padding: theme.spacing(3),
  },
  icon: {
    color: theme.palette.text.primary,
  },
  gameTypeIcon: {
    color: theme.palette.text.tertiary,
    width: 16,
    height: 16,
  },
  teamModeIcon: {
    color: theme.palette.text.tertiary,
    width: 12,
  },
  secondaryIcon: {
    color: alpha(theme.palette.common.white, 0.5),
    overflow: 'visible',
  },
})

export const findJudgeInTheContest = (userId, judges) => (judges || []).find((i) => i?.user_id === userId)

export const isUserAJudgeInTheContest = (userId, judges) => !!findJudgeInTheContest(userId, judges)

export const isJudgeAllowedForTheRound = (userId, contest, currentRoundLimit) => {
  const owner = userId === contest?.owner_id
  const judgeData = findJudgeInTheContest(userId, contest?.judges)
  const isEvaluationWithRounds = !!contest?.round_limits?.length
  const isEvaluationRound = getIsEvaluationRound(contest?.state)
  // Owner has an empty array in rounds
  return !!(
    owner ||
    (isEvaluationWithRounds && isEvaluationRound
      ? judgeData?.rounds?.includes(`top ${currentRoundLimit}`)
      : judgeData?.rounds?.includes(contest?.state)) ||
    judgeData?.rounds?.includes(CONTEST_LIFE_STATES.FINAL)
  )
}

export const isAllCurrentJudgesVoted = (allVotedJudges = [], currentJudges = []) => {
  const currentJudgesStatus = allVotedJudges.filter((votedJudge) =>
    currentJudges.find((currentJudge) => votedJudge?.user_id === currentJudge?.user_id),
  )
  return currentJudges?.length === currentJudgesStatus?.length
}

export const checkConfirmation = (votedJudges = [], currentJudges = [], userId) => {
  const isAllVoted = isAllCurrentJudgesVoted(votedJudges, currentJudges)
  const isUserVoted = votedJudges.find((judge) => judge.user_id === userId)
  const isRevote =
    isAllVoted && votedJudges.reduce((acc, judge) => (!judge.confirm ? acc + 1 : acc), 0) >= votedJudges.length / 2
  return { isAllVoted, isRevote, isUserVoted: !!isUserVoted }
}

export const contestStepsForMobile = {
  0: { name: 'Submission', stepStartDate: (event) => getDayMonth(event.time_add_maps_begin) },
  1: { name: 'Qualification', stepStartDate: (event) => getDayMonth(event.time_qualification_begin) },
  2: { name: 'Evaluation', stepStartDate: (event) => getDayMonth(event.time_evaluation_begin) },
  3: { name: 'Final', stepStartDate: (event) => getDayMonth(event.time_final_begin) },
}

export const plainTextTransformation = (text) => {
  if (!text) return
  const replacedText = text
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ')
    .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))

  return replacedText.replace(/<[^>]+>/g, '')
}
